@font-face {
  font-family: "DB Adman X";
  src: local("DB Adman X"), url("assets/font/DB Adman X.ttf") format("truetype");
}

@font-face {
  font-family: "DB Adman X Bold";
  src: local("DB Adman X"),
    url("assets/font/DB Adman X Bd.ttf") format("truetype");
}

@font-face {
  font-family: "DB Adman X Italic";
  src: local("DB Adman X"),
    url("assets/font/DB Adman X Italic.ttf") format("truetype");
}

@font-face {
  font-family: "DB Adman X Bold-Italic";
  src: local("DB Adman X"),
    url("assets/font/DB Adman X BdIt.ttf") format("truetype");
}

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  font-family: "DB Adman X" !important;
  overflow: hidden;
  background-color: var(--white);
}

.ant-popover-arrow {
  display: none !important;
}

.ant-popover-inner {
  border-radius: 10px !important;
}

.ant-popover-inner-content {
  height: 200px;
  padding: 20px 10px 30px !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  font-size: 24px !important;
  font-weight: 700;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  font-size: 18px !important;
  font-weight: 500;
}
